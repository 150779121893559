import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-basis: 300px;

  .avatar {
    border-radius: 50%;
    overflow: hidden;
    width: 150px;
    height: 150px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    &:hover {
      img {
        transform: scale(1.005);
      }
    }
  }

  > h2 {
    margin-top: 24px;
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors['gray-900']};
    text-align: center;
  }

  > p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 8px;
    color: ${({ theme }) => theme.colors['gray-600']};
  }

  > span {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors['gray-600']};
  }

  .links {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 18px;
    gap: 1.5rem;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s ease all;

      svg {
        font-size: 2rem;
        color: ${({ theme }) => theme.colors['gray-600']};
      }

      &:hover {
        transform: scale(1.05);
      }
    }
  }
`;
