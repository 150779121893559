import React from 'react';
import Slider, { Settings } from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Container, Content } from './styles';

const settings: Settings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  autoplay: true,
  autoplaySpeed: 2500,
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: 400,
      settings: { slidesToShow: 1, slidesToScroll: 1 },
    },
    {
      breakpoint: 800,
      settings: { slidesToShow: 2, slidesToScroll: 2 },
    },
    {
      breakpoint: 1024,
      settings: { slidesToShow: 3, slidesToScroll: 3 },
    },
  ],
};

export const Supporters: React.FC = () => {
  return (
    <Container>
      <Content>
        <h2>PARTNERS & sponsors</h2>

        <Slider {...settings}>
          <div>
            <div className="card">
              <img src="/assets/images/uefs.png" alt="" />
            </div>
          </div>

          <div>
            <div className="card">
              <img src="/assets/images/ufba.png" alt="" />
            </div>
          </div>

          <div>
            <div className="card">
              <img src="/assets/images/fapesb.png" alt="" />
            </div>
          </div>

          <div>
            <div className="card">
              <img src="/assets/images/fiocruz.png" alt="" />
            </div>
          </div>

          <div>
            <div className="card">
              <img src="/assets/images/cnpq.png" alt="" />
            </div>
          </div>
        </Slider>
      </Content>
    </Container>
  );
};
