import dayjs from 'dayjs';
import parse from 'html-react-parser';
import React from 'react';
import { Link } from 'react-router-dom';
import { NewsDTO } from '../../dtos/NewsDTO';

import { Container } from './styles';

interface ItemPostProps {
  data: NewsDTO;
}

export const ItemPost: React.FC<ItemPostProps> = ({
  data: { id, title, description, createdAt, imageUrl },
}) => {
  return (
    <Container>
      <Link to={`/news/post/?id=${id}`}>
        <div className="image">
          <img src={imageUrl} alt="Image_Post" />
        </div>
        <span>{dayjs(createdAt).format('DD/MM/YYYY')}</span>
        <h2>{title}</h2>

        <div className="description">{parse(description)}</div>
      </Link>
    </Container>
  );
};
