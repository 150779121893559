import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  label {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 25.2px;
    margin: 2rem 0;
  }
`;

export const UploadArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 285px;
  border: 2px dashed #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  cursor: pointer;
  margin-bottom: 16px;

  p {
    color: #888;
    font-size: 1.2rem;
  }
`;

export const PreviewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  width: 100%;
`;

export const FileContainer = styled.div`
  position: relative;
  width: 320px;
  height: 285px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  .file-name {
    padding: 4px;
    font-size: 0.9rem;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    width: 100%;
    text-align: center;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: zoom-in;
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 4px;

    button {
      background-color: rgba(255, 255, 255, 0.7);
      border: none;
      border-radius: 4px;
      padding: 4px;
      cursor: pointer;

      &:hover {
        background-color: rgba(255, 255, 255, 1);
      }
    }
  }
`;

export const ErrorContainer = styled.div`
  position: relative;
  width: 320px;
  height: 285px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f8d7da;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  .error-message {
    color: #721c24;
    font-weight: bold;
  }

  button {
    position: absolute;
    top: 8px;
    right: 8px;
    background: none;
    border: none;
    color: #721c24;
    cursor: pointer;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Modal = styled.div`
  position: relative;
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  width: 90%;
  max-width: 90%;
  height: 90%;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

export const ModalButtonContainer = styled.div`
  position: absolute;
  top: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 10px;

  button {
    background-color: rgba(255, 255, 255, 0.7);
    border: none;
    border-radius: 4px;
    padding: 8px;
    cursor: pointer;

    &:hover {
      background-color: rgba(255, 255, 255, 1);
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }
`;
