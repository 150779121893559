import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  justify-content: center;
  margin-top: 80px;
`;

export const Content = styled.div`
  display: flex;
  max-width: 1016px;
  flex: 1;
  flex-direction: column;
  margin: 3.5rem 1rem;

  .description {
    display: flex;
    margin-top: 56px;
    justify-content: space-between;
    gap: 2rem;

    .col-left {
      max-width: 416px;

      img {
        width: 100%;
        object-fit: contain;
      }
    }
    .col-right {
      max-width: 496px;
      display: flex;
      flex-direction: column;
      gap: 2rem;

      p {
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 33.6px;
        color: ${({ theme }) => theme.colors['gray-700']};
      }
    }
  }

  .items {
    display: flex;
    gap: 1.5rem;
    margin-top: 96px;
    flex-wrap: wrap;

    .item {
      flex: 1;

      span {
        line-height: 38.4px;
        font-size: 2rem;
        font-weight: 700;
        color: ${({ theme }) => theme.colors['red-700']};
      }

      p {
        margin-top: 32px;
        line-height: 30px;
        font-weight: 400;
        font-size: 1.25rem;
        color: ${({ theme }) => theme.colors['gray-700']};
      }
    }
  }

  @media (max-width: 767.98px) {
    .description {
      flex-wrap: wrap;

      .col-left,
      .col-right {
        flex: 100%;
        width: 100%;
      }
    }

    .items {
      .item {
        flex: 100%;
        width: 100%;
      }
    }
  }
`;
