import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 146px;
  height: 54px;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors['red-500']};
  border-radius: 0.5rem;
  transition: 0.3s ease all;

  &:hover {
    background: ${({ theme }) => theme.colors['red-700']};
  }
`;
