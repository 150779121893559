import styled, { css } from 'styled-components';

interface ContainerProps {}

export const Container = styled.table<ContainerProps>`
  margin: 20px auto;
  border-collapse: collapse;
  word-wrap: break-word;
  overflow-wrap: break-word;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  width: 100%;

  th {
    padding: 15px;
    text-align: center;
    font-weight: 700;
    font-size: 1rem;
    color: ${({ theme }) => theme.colors['red-700']};
    text-transform: uppercase;
    background-color: ${({ theme }) => theme.colors['gray-50']};
    border-bottom: 2px solid ${({ theme }) => theme.colors['red-700']};
  }

  td {
    text-align: center;
    font-weight: 500;
    font-size: 0.875rem;
    padding: 1.2rem;
    color: ${({ theme }) => theme.colors['gray-800']};
  }

  tr:nth-child(even) {
    background-color: ${({ theme }) => theme.colors['gray-100']};
  }

  tbody {
    tr:hover {
      background-color: ${({ theme }) => theme.colors['red-100']};
      cursor: pointer;
    }
  }

  th, td {
    border-bottom: 1px solid ${({ theme }) => theme.colors['red-700']};
  }

  @media (max-width: 768px) {
    th, td {
      padding: 10px;
      font-size: 0.75rem; 
    }
  }
`;

export const TableWrapper = styled.div`
  display: block;
  overflow-x: auto;
  width: 100%;
  border-radius: 20px;

  table {
    width: 100%;
    table-layout: fixed;
  }
`;
