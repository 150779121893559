import React, { useState } from 'react';
import { PageHeader } from '../PageHeader';
import { Container, Content } from './styles';
import { ImageSelector } from '../ImageSelector';
import { CustomButton } from '../CustomButton';
import axios from 'axios';
import { CustomTable } from '../CustomTable';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { FaInfoCircle } from 'react-icons/fa';

interface ToolClassifierProps {}

interface ReturnApiDTO {
  filename: string;
  title: string;
  isLesion: boolean;
  result: number;
}

export const ToolClassifier: React.FC<ToolClassifierProps> = () => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [predictions, setPredictions] = useState<ReturnApiDTO[][]>([]);
  const [loading, setLoading] = useState(false);
  const [legalNotice, setLegalNotice] = useState(false);
  const apiRouter = process.env.REACT_APP_TOOL_API!;

  const handleUpload = async () => {
    setPredictions([]);

    if (selectedFiles.length > 0) {
      setLoading(true);
      const formData = new FormData();

      selectedFiles.forEach((file) => {
        formData.append('files', file);
      });

      try {
        const response = await axios.post(apiRouter, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        const resp = response.data.result;
        setPredictions(resp);
      } catch (error) {
        console.error('Erro ao fazer o upload:', error);
        alert(
          'Something is wrong. Try again later!\nIf this problem persists, please contact us!',
        );
      } finally {
        setLoading(false);
      }
    } else {
      alert('Select at least one image to upload.');
    }
  };

  const clearPredictions = () => {
    setPredictions([]);
  };

  const generateTableHeaders = (predictions: ReturnApiDTO[][]) => {
    const filenames = predictions.map(
      (predictionArray) => predictionArray[0]?.filename || 'Unknown Image'
    );
    return ['Lesion', ...filenames];
  };

  const generateTableData = (predictions: ReturnApiDTO[][]) => {
    const lesionTitles = predictions[0]
      .filter((item) => item.title !== 'Normal')
      .map((item) => item.title);

    return lesionTitles.map((lesionTitle, rowIndex) => {
      const row = [
        <td key={`lesion-${rowIndex}`}>
          {lesionTitle === 'ECDC' ? (
            <span className="tooltip">
              ECDC
              <FaInfoCircle
                data-tip="Epithelial Cell Degenerative Changes"
                style={{ marginLeft: '10px', color: 'blue' }}
              />
              <span className="tooltiptext">Epithelial Cell Degenerative Changes</span>
            </span>
          ) : (
            lesionTitle
          )}
        </td>,
      ];

      for (let i = 0; i < predictions.length; i++) {
        const predictionArray = predictions[i];
        const lesionPrediction = predictionArray.find(
          (item) => item.title === lesionTitle
        );
        const isLesion = lesionPrediction ? lesionPrediction.isLesion : false;

        row.push(
          <td
            key={`prediction-${rowIndex}-${i}`}
            style={{ color: isLesion ? 'red' : 'gray' }}
          >
            {isLesion ? 'YES' : 'NO'}
          </td>
        );
      }

      return <tr key={`row-${rowIndex}`}>{row}</tr>;
    });
  };

  return (
    <Container>
      <Content>
        <PageHeader
          title="PATHOSPOTTER LESION CLASSIFIER"
          subTitle="Classification of some glomerular lesions."
        />
        <label>
          Select images that are stained using HE or PAS. The maximum allowed image size is 10 MB.
          Ensure that the glomerulus occupies at least 80% of the image area. By uploading an image,
          you agree to the terms outlined in the Legal Notice.
        </label>
        <div className="main-content">
          <div
            className={
              selectedFiles.length === 1 ? 'image-section' : 'image-section-total'
            }
          >
            <ImageSelector
              label=""
              setSelectedFiles={setSelectedFiles}
              selectedFiles={selectedFiles}
              clearPredictions={clearPredictions}
            />
            {selectedFiles.length > 0 && (
              <CustomButton
                label="Send Images"
                onClick={handleUpload}
                disabled={loading}
              />
            )}
          </div>
          {selectedFiles.length > 0 && predictions.length === 1 && (
            <div className="table-section">
              {loading && (
                <div>
                  <img src="/assets/svg/spinner.svg" alt="loading" />
                </div>
              )}
              <CustomTable
                data={predictions[0]
                  .filter((item) => item.title !== 'Normal')
                  .map((item, index) => (
                    <tr key={index}>
                      <td>
                        {item.title === 'ECDC' ? (
                          <span className="tooltip">
                            ECDC
                            <FaInfoCircle
                              data-tip="Epithelial Cell Degenerative Changes"
                              style={{ marginLeft: '10px', color: 'blue' }}
                            />
                            <span className="tooltiptext">
                              Epithelial Cell Degenerative Changes
                            </span>
                          </span>
                        ) : (
                          item.title
                        )}
                      </td>
                      <td style={{ color: item.isLesion ? 'red' : 'gray' }}>
                        {item.isLesion ? 'YES' : 'NO'}
                      </td>
                    </tr>
                  ))}
                headers={['Lesion', 'Prediction']}
              />
            </div>
          )}
        </div>
        {selectedFiles.length > 0 && predictions.length > 1 && (
          <div className="main-content">
            <div className="table-section-total">
              {loading && (
                <div>
                  <img src="/assets/svg/spinner.svg" alt="loading" />
                </div>
              )}
              <CustomTable
                data={generateTableData(predictions)}
                headers={generateTableHeaders(predictions)}
              />
            </div>
          </div>
        )}
        <div className="actions">
          <button
            onClick={() => {
              setLegalNotice(!legalNotice);
            }}
            type="button"
            className="button-abstract"
          >
            {legalNotice ? <FiChevronUp /> : <FiChevronDown />}
            <span>Legal Notice</span>
          </button>
        </div>
        {legalNotice && (
          <div className="box-abstract">
            <p className="text-secondary">
              The information contained on this site, or provided by its
              request, is provided for informational or academical purposes only
              and is not intended to be a medical or health advice. We do not
              send or sell this information for the purpose of diagnosis,
              treatment, cure or prevention of any disease. We do not recommend
              any particular form of medical treatment or that people administer
              their own health problems without the advice of a licensed health
              professional. Information found or received through this site
              should not be used in lieu of a visit, call, consultation or
              advice from a health care provider. If you suspect you have a
              medical problem, or if you have any questions about your health
              care, call or see your doctor.
            </p>
            <p className="text-secondary">
              This site, or any part thereof, may not be reproduced, duplicated,
              copied, sold, resold, visited or otherwise exploited for any
              commercial purpose without the express written consent of the
              persons responsible for the Pathospotter Project. You may not use
              any "meta tag" or any other "hidden text" using the Pathospotter
              name or trademark without the express written consent of the
              project's owners. Any unauthorised use will be subject to
              investigation and application of legal penalties.
            </p>
            <p className="text-secondary">
              As a condition of your use of this site, you warrant that you will
              not use it for any purpose that is unlawful or prohibited by this
              notice. You may not use this site in any manner that damages,
              disables, overburden, or harms you. You may not obtain, or attempt
              to obtain, through this site, materials or information by any
              means that has not been intentionally made available or provided
              through it.
            </p>
            <p className="text-secondary">
              By submitting an image for review, you agree that the Pathospotter
              Project may use it, free of any liens or legal obligation to you,
              the author of the image, or the owner of the image direct use of
              the image; in order to develop, test and improve their algorithms,
              including conducting surveys, and testing and solving problems
              related to the operation of systems and products related to the
              PathoSpotter Project. If you do not own the image rights you have
              submitted, you may be legally responsible for using this image
              without the permission of the owners of image's rights and subject
              to appropriate legal penalties.
            </p>
            <p className="text-secondary">
              This site collects information from and about computers,
              smartphones, and other devices used to connect to this page, and
              uses this information for security control and to monitor and
              enhance products and systems related to the PathoSpotter Project.
            </p>
          </div>
        )}
      </Content>
    </Container>
  );
};
