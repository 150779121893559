import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { HashRouter } from 'react-router-dom'; // Alteração para HashRouter
import Aos from 'aos';
import { theme } from './styles/theme';

import GlobalStyle from './styles/global';
import { Routers } from './routers';

import 'aos/dist/aos.css';

Aos.init({
  offset: 100,
  duration: 400,
});

export const App: React.FC = () => {
  useEffect(() => {
    Aos.refresh();
  }, []);

  return (
    <ThemeProvider theme={theme.default}>
      <HashRouter>
        <Routers />
        <GlobalStyle />
      </HashRouter> 
    </ThemeProvider>
  );
};
