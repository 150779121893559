import React from 'react';
import { PageHeader } from '../PageHeader';
import { SeeAll } from '../SeeAll';
import { Container, Content } from './styles';
import { Link } from 'react-router-dom';

interface ToolsProps {
  isSeeAll?: boolean;
}

export const Tools: React.FC<ToolsProps> = ({ isSeeAll = false }) => (
  <Container isSeeAll={isSeeAll} data-aos="fade-up" data-aos-duration="800">
    <Content>
      <PageHeader
        title="Tools"
        subTitle="Tools avalilable for pathologists (in case of interest, please contact us)."
      />

      <div className="items">
        <Link
          className="item"
          to="tools/classifier"
          rel="noopener noreferrer"
        >
          <div>
            <img src="/assets/svg/venn-diagram.svg" alt="Ven Diagram Icon" />
            <span>PathoSpotter Classifier</span>
            <p>Classification of some glomerular lesions.</p>
          </div>
        </Link>

        <Link
          className="item"
          to="tools/segmenter" 
          rel="noopener noreferrer"
        >
          <div>
            <img src="/assets/svg/grid-icon.svg" alt="Grid Icon" />
            <span>PathoSpotter Segmenter</span>
            <p>Segmentation of glomerular lesion images.</p>
          </div>
        </Link>

        {/* <Link
          className="item"
          to="tools/segmenter" 
          rel="noopener noreferrer"
        >
          <div>
          <div className='image-container'>
            <img src="/assets/svg/grid-icon.svg" alt="Grid Icon" />
            <img src="/assets/svg/venn-diagram.svg" alt="Ven Diagram Icon" />
            </div>
            
            <span>PathoSpotter Classifier + Segmenter</span>
            <p>Classification and Segmentation of some glomerular lesions.</p>
          </div>
        </Link> */}

        <Link
          className="item" 
          to=" "//href=" " 
          //target="_blank"
          rel="noopener noreferrer"
        >
          <div>
            <img src="/assets/svg/maximize-icon.svg" alt="Grid Icon" />
            <span>PathoSpotter Labeller</span>
            <p>Collaborative tool to facilitate consensus in image analysis.</p>
          </div>
        </Link>

        <Link
          className="item"
          to=" " //href=" " 
          //target="_blank"
          rel="noopener noreferrer"
        >
          <div>
            <img src="/assets/svg/search-icon.svg" alt="Grid Icon" />
            <span>PathoSpotter Search</span>
            <p>Content Based Image Recovery for digital biopsies.</p>
          </div>
        </Link>

        <a
          className="item"
          //href=" "
          //target="_blank"
          rel="noopener noreferrer"
        >
          <div>
            <img src="/assets/svg/box-icon.svg" alt="Grid Icon" />
            <span>PathoSpotter iMap</span>
            <p>Interactive map of nephropathology and computer science.</p>
          </div>
        </a>

        {isSeeAll && (
          <>
            <a
              className="item"
              href="https://pathospotter.bahia.fiocruz.br/pathospotterdrive/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>
                <img src="/assets/svg/fi-x.svg" alt="Grid Icon" />
                <span>PathoSpotter Drive</span>
                <p>Drive in the cloud.</p>
                <span className="private">Private</span>
              </div>
            </a>

            <a
              className="item"
              href="http://pathospotter-cytomine-core.bahia.fiocruz.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>
                <img src="/assets/svg/box-2.svg" alt="Grid Icon" />
                <span>PathoSpotter Annotation Tool</span>
                <p>Image annotation based on the Cytomine project.</p>
                <span className="private">Private</span>
              </div>
            </a>
          </>
        )}
      </div>

      {!isSeeAll && (
        <div className="button">
          <SeeAll path="/tools" />
        </div>
      )}
    </Content>
  </Container>
);
