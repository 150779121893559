import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.colors['gray-50']};
`;

export const Content = styled.div`
  display: flex;
  max-width: 1016px;
  flex: 1;
  flex-direction: column;
  margin: 3.5rem 1rem;

  .p-logo {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    img {
      display: flex;
      width: 239.42px;
      height: 32px;
    }

    .div-texto-logo {
      margin-top: 16px;

      p {
        display: flex;
        flex-direction: column;
        font-weight: 400;
        font-size: 1rem;
        line-height: 24px;

        color: ${({ theme }) => theme.colors['gray-700']};
      }
    }

    .button-to-top {
      display: flex;
      align-items: center;

      button {
        transition: 0.5s ease all;
        display: flex;
        width: 64px;
        height: 64px;
        border-radius: 4px;
        padding: 16px;
        background: ${({ theme }) => theme.colors['red-500']};

        &:hover {
          background: ${({ theme }) => theme.colors['red-700']};
        }
      }
    }
  }

  .menu-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 56px;
    flex-wrap: wrap;
    gap: 2rem;

    .col-left,
    .col-right {
      display: flex;
      gap: 2rem;

      .item {
        h2 {
          font-weight: 700;
          font-size: 1.25rem;
          line-height: 30px;
          color: ${({ theme }) => theme.colors['gray-900']};
        }

        .menu-items {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          p,
          a {
            color: ${({ theme }) => theme.colors['gray-700']};
          }

          a {
            transition: 0.3s all ease;
            p,
            &:hover {
              color: ${({ theme }) => theme.colors['blue-300']};

              text-decoration: underline;
            }
          }
        }
      }
    }
  }
`;
