import React from 'react';
import ReactGA4 from 'react-ga4';

import { About } from '../../components/About';
import { Banner } from '../../components/Banner';
import { Footer } from '../../components/Footer';
import { News } from '../../components/News';
import { Supporters } from '../../components/Supporters';
import { Tools } from '../../components/Tools';
import { Container } from './styles';

ReactGA4.send({ hitType: 'pageview', page: window.location.pathname });

export const Home: React.FC = () => (
  <Container>
    <Banner />
    <About />
    <Tools />
    <News />
    <Supporters />
    <Footer />
  </Container>
);
