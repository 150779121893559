import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA4 from 'react-ga4';
import { App } from './App';

// Google Analytics
ReactGA4.initialize('G-53RRZ1694K');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
