import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 56px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid ${({ theme }) => theme.colors['red-500']};
  overflow: hidden;
  border-radius: 0.5rem;

  .box-header {
    background: ${({ theme }) => theme.colors['red-500']};
    color: ${({ theme }) => theme.colors.white};
    padding: 1rem;
    font-size: 1rem;
  }

  .box-body {
    display: flex;

    & + .box-body {
      border-top: 1px solid ${({ theme }) => theme.colors['red-500']};
    }

    .icon-book {
      padding: 1rem;

      svg {
        font-size: 1.3rem;
      }
    }

    .box-content {
      padding: 1rem;
      padding-left: 0;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      h2 {
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 30px;
        color: ${({ theme }) => theme.colors['gray-900']};
      }

      p {
        font-size: 1rem;
        font-weight: 400;
        line-height: 24px;
        color: ${({ theme }) => theme.colors['gray-700']};
      }

      .actions {
        display: flex;
        justify-content: space-between;
        margin-top: 0.5rem;

        .button-abstract,
        .links a {
          display: flex;
          align-items: center;
          gap: 1rem;

          svg {
            font-size: 2rem;
          }
        }

        .links {
          display: flex;
          gap: 1rem;

          a {
            gap: 0.5rem;
          }

          span {
            font-size: 1rem;
            font-weight: 400;
            line-height: 24px;
            color: ${({ theme }) => theme.colors['gray-900']};
          }
        }
      }

      .box-abstract {
        opacity: 0;
        height: 0;
        transition: 0.3s ease all;
        visibility: hidden;

        &.active {
          height: auto;
          opacity: 1;
          visibility: visible;
        }

        span {
          display: flex;
          margin-top: 24px;
          font-weight: 600;
          font-size: 1rem;
          line-height: 24px;
          color: ${({ theme }) => theme.colors['gray-900']};
        }

        p {
          margin-top: 4px;
          font-weight: 400;
          font-size: 1rem;
          line-height: 24px;
          color: ${({ theme }) => theme.colors['gray-700']};
        }
      }
    }
  }
`;
