import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  max-width: 1016px;
  flex-direction: column;
  flex: 1;
  margin: 3.5rem 1rem;

  .current-span {
    display: flex;
    flex-direction: column;
    margin-top: 56px;

    > span {
      display: flex;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 33.6px;
      flex-direction: column;
      color: ${({ theme }) => theme.colors['gray-600']};
    }
  }

  .items {
    display: flex;
    flex-wrap: wrap;
    gap: 4rem 2rem;
    margin-top: 3rem;
  }

  > .footer {
    display: flex;
    flex-direction: column;
    margin-top: 120px;

    .former {
      display: flex;
      flex-direction: column;

      > span {
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 33.6px;
        color: ${({ theme }) => theme.colors['gray-600']};
      }
    }

    > .items-footer {
      display: flex;
      flex-wrap: wrap;

      .group-items {
        display: flex;
        gap: 10px 139px;
        margin-top: 56px;
        flex-wrap: wrap;
        align-items: stretch;

        .item-footer {
          display: flex;
          flex-direction: column;
          flex: 1;

          > h2 {
            margin-top: 32px;
            font-weight: 600;
            font-size: 1.25rem;
            line-height: 30px;
            color: ${({ theme }) => theme.colors['gray-900']};
          }

          > p {
            font-weight: 400;
            font-size: 1rem;
            line-height: 24px;
            color: ${({ theme }) => theme.colors['gray-700']};
          }
        }
      }

      .group-items-two {
        display: flex;
        gap: 10px 139px;
        margin-top: 32px;
        flex-wrap: wrap;

        .item-footer {
          flex: 1;

          > h2 {
            margin-top: 32px;
            font-weight: 600;
            font-size: 1.25rem;
            line-height: 30px;
            color: ${({ theme }) => theme.colors['gray-900']};
          }

          > p {
            font-weight: 400;
            font-size: 1rem;
            line-height: 24px;
            color: ${({ theme }) => theme.colors['gray-700']};
          }
        }
      }
    }
  }
`;
