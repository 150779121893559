import React from 'react';
import { FiMail, FiGlobe, FiFileText } from 'react-icons/fi';

import { UserTeamDTO } from '../../dtos/UserTeamDTO';

import { Container } from './styles';

interface CompTeamProps {
  data: UserTeamDTO;
}

export const CompTeam: React.FC<CompTeamProps> = ({
  data: { fullName, office, avatar, contacts },
}) => {
  return (
    <Container>
      <div className="avatar">
        <img src={avatar} alt="Avatar" />
      </div>
      <h2>{fullName}</h2>

      <p>{office} </p>

      <div className="links">
        {contacts.email && (
          <a
            href={`mailto:${contacts.email}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FiMail />
          </a>
        )}

        {contacts.site && (
          <a href={contacts.site} target="_blank" rel="noopener noreferrer">
            <FiGlobe />
          </a>
        )}

        {contacts.document && (
          <a href={contacts.document} target="_blank" rel="noopener noreferrer">
            <FiFileText />
          </a>
        )}
      </div>
    </Container>
  );
};
