import React from 'react';
import ReactGA4 from 'react-ga4';
import { BoxPublication } from '../../components/BoxPublication';
import { PageHeader } from '../../components/PageHeader';
import { publications } from '../../database/mock/publications';

import { Container, Content } from './styles';

// Google Analytics
ReactGA4.send({ hitType: 'pageview', page: window.location.pathname });

export const Publications: React.FC = () => {
  return (
    <Container data-aos="fade-up" data-aos-duration="800">
      <Content>
        <PageHeader
          // title="publications"
          title=""
          // subTitle="Papers about Pathospotter."
          subTitle=""
        />
        <BoxPublication publications={publications} />
      </Content>
    </Container>
  );
};
