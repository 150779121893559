import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  max-width: 1016px;
  flex: 1;
  flex-direction: column;
  margin: 0 1rem;
  position: relative;
  margin-top: 48px;

  &::after {
    content: '';
    position: absolute;
    height: 168px;
    width: 88px;
    left: -160px;
    bottom: 370px;
    background: url('/assets/svg/vector.svg');
  }

  > .items {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem 1rem;
    margin-top: 80px;
    justify-content: space-between;
  }

  .button {
    display: flex;
    justify-content: center;
    margin-top: 110px;
  }

  @media (max-width: 1662px) {
    &::after {
      background: none;
    }
  }
`;
