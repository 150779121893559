import React from 'react';

import { Container } from './styles';

interface CustomButtonProps {
  label: string;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  disabled?: boolean;
}

export const CustomButton: React.FC<CustomButtonProps> = ({
  label,
  onClick,
  disabled,
}) => {
  return (
    <Container onClick={onClick} disabled={disabled}>
      {label}
    </Container>
  );
};
