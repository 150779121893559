import styled from 'styled-components';

export const Container = styled.section`
  span {
    font-weight: 700;
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors['red-700']};
    text-transform: uppercase;
  }

  h4 {
    font-weight: 500;
    font-size: 2rem;
    line-height: 41.6px;
    color: ${({ theme }) => theme.colors['gray-900']};
    margin-top: 24px;
  }
`;
