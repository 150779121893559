import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 146px;
  height: 54px;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors['red-500']};
  border-radius: 0.5rem;
  transition: 0.3s ease all;
  margin: 1rem 0 1rem 0;

  &:hover {
    background: ${({ theme }) => theme.colors['red-700']};
  }
  &:disabled {
    background: ${({ theme }) => theme.colors['gray-800']};
  }
`;
