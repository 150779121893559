import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  a {
    .image {
      min-width: 317.33px;
      width: 100%;
      height: 259.57px;
      border-radius: 0.5rem;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.3s ease all;
      }
    }

    span {
      display: flex;
      font-weight: 400;
      margin-top: 16px;
      line-height: 24px;
      font-size: 1rem;
      color: ${({ theme }) => theme.colors['gray-700']};
    }

    h2 {
      margin-top: 8px;
      font-size: 1.25rem;
      line-height: 30px;
      color: ${({ theme }) => theme.colors['gray-900']};
    }

    .description {
      margin-top: 8px;
      line-height: 24px;
      font-size: 1rem;
      color: ${({ theme }) => theme.colors['gray-700']};

      overflow: hidden; // Removendo barra de rolagem
      text-overflow: ellipsis; // Adicionando "..." ao final
      display: -webkit-box;
      -webkit-line-clamp: 3; // Quantidade de linhas
      -webkit-box-orient: vertical;
    }

    &:hover {
      .image {
        img {
          transform: scale(1.05);
        }
      }
    }
  }
`;
