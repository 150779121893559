import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { FiMenu, FiX } from 'react-icons/fi';

import { Container, Content } from './styles';

export const Header: React.FC = () => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [isMenuActive, setIsMenuActive] = useState(false);

  useEffect(() => {
    document.documentElement.style.overflow = isMenuActive ? 'hidden' : 'auto';
  }, [isMenuActive]);

  useEffect(() => {
    if (menuRef.current) {
      const links = menuRef.current.childNodes;

      links.forEach(link => {
        link.addEventListener('click', () => {
          setIsMenuActive(false);
        });
      });
    }
  }, []);

  return (
    <Container>
      <Content>
        <div className="logo">
          <Link to="/">
            <img
              src="/assets/images/LogoPathoSpotter.png"
              width="380"
              height="74"
              alt="PathoSpotter"
            />
          </Link>
        </div>

        <nav>
          <button
            type="button"
            className="menu-mobile"
            onClick={() => {
              setIsMenuActive(!isMenuActive);
            }}
          >
            {isMenuActive ? <FiX /> : <FiMenu />}
          </button>

          <div ref={menuRef} className={`menu ${isMenuActive ? 'active' : ''}`}>
            <NavLink to="/tools">Tools</NavLink>
            <NavLink to="/news">News</NavLink>
            <NavLink to="/publications">Publications</NavLink>
            <NavLink to="/team">Team</NavLink>
          </div>
        </nav>
      </Content>
    </Container>
  );
};
