export const theme = {
  default: {
    colors: {
      white: '#FFFF',

      'gray-50': '#EFEFEF',
      'gray-100': '#DFDFDF',
      'gray-600': '#4B4748',
      'gray-700': '#4D4647',
      'gray-800': '#363636',
      'gray-900': '#141414',

      black: '#000000',

      'red-100': '#E8DCDE',
      'red-500': '#A33B4E',
      'red-700': '#702331',

      'blue-300': '#517cb4',
    },
  },
};
