import React, { useState, useCallback } from 'react';
import parse from 'html-react-parser';

import {
  FiBook,
  FiChevronDown,
  FiChevronUp,
  FiFile,
  FiGithub,
} from 'react-icons/fi';

import { Container, Content } from './styles';
import { PublicationsDTO } from '../../dtos/PublicationDTO';

interface BoxPublicationProps {
  publications: PublicationsDTO[];
}

export const BoxPublication: React.FC<BoxPublicationProps> = ({
  publications,
}) => {
  const [activeAbstractId, setActiveAbstractId] = useState('');

  const handleActiveAbstract = useCallback(
    (id: string) => {
      setActiveAbstractId(activeAbstractId === id ? '' : id);
    },
    [activeAbstractId],
  );

  return (
    <Container data-aos="fade-up" data-aos-duration="800">
      {publications.map(({ publicationPeriod }) => (
        <Content key={publicationPeriod.year}>
          <div className="box-header">
            <h2>{publicationPeriod.year}</h2>
          </div>

          {publicationPeriod.publications.map(publication => (
            <div key={publication.id} className={`box-body `}>
              <div className="icon-book">
                <FiBook />
              </div>

              <div className="box-content">
                <a
                  href={publication.titlePubli.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h2>{publication.titlePubli.title}</h2>
                </a>

                <p>{parse(publication.description)}</p>

                <div className="actions">
                  <button
                    onClick={() => {
                      handleActiveAbstract(publication.id);
                    }}
                    type="button"
                    className="button-abstract"
                  >
                    {activeAbstractId === publication.id ? (
                      <FiChevronUp />
                    ) : (
                      <FiChevronDown />
                    )}
                    <span>Abstract</span>
                  </button>

                  <div className="links">
                    {publication.links.sourceCode && (
                      <a
                        href={publication.links.sourceCode}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FiGithub />
                        <span>Visit the project on Github</span>
                      </a>
                    )}

                    {publication.links.downloadCode && (
                      <a
                        href={publication.links.downloadCode}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FiFile />
                        <span>Click to download</span>
                      </a>
                    )}
                  </div>
                </div>

                <div
                  className={`box-abstract ${
                    activeAbstractId === publication.id ? 'active' : ''
                  }`}
                >
                  <span>{publication.abstract.title}</span>

                  <p>{publication.abstract.description}</p>
                </div>
              </div>
            </div>
          ))}
        </Content>
      ))}
    </Container>
  );
};
