import React from 'react';
import ReactGA4 from 'react-ga4';

import { Tools } from '../../components/Tools';
import { Container } from './styles';

// Google Analytics
ReactGA4.send({ hitType: 'pageview', page: window.location.pathname });

export const PageTools: React.FC = () => {
  return (
    <Container>
      <Tools isSeeAll />
    </Container>
  );
};
