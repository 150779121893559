import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  max-width: 1016px;
  flex: 1;
  flex-direction: column;
  margin: 3.5rem 1rem;
  position: relative;

  > .items {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem 1rem;
    margin-top: 80px;
  }
`;
