import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  max-width: 1724px;
  flex: 1;
  display: flex;
  justify-content: space-between;
  height: 6rem;
  margin-top: 1rem;
  padding: 0 2rem;

  nav {
    .menu-mobile {
      opacity: 0;
      visibility: hidden;
      width: 3rem;
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2.5rem;
      position: absolute;
      top: 1rem;
      right: 1rem;
      z-index: 1000;
      transition: 0.3s ease all;
      color: ${({ theme }) => theme.colors['gray-900']};

      &:hover {
        transform: scale(1.05);
      }
    }

    .menu {
      display: flex;
      flex-wrap: wrap;
      padding-top: 1.5rem;
      gap: 2rem;

      a {
        color: ${({ theme }) => theme.colors['gray-900']};
        transition: 0.3s ease all;
        position: relative;
        height: 24px;
        font-size: 1.5rem;
        &::before {
          content: '';
          transition: 0.3s ease all;
          position: absolute;
          bottom: -4px;
          width: 100%;
          height: 2px;
          background: ${({ theme }) => theme.colors['red-500']};
          opacity: 0;
        }

        &:hover {
          transform: translateY(-4px);

          &::before {
            bottom: 0;
            opacity: 1;
          }
        }

        &.active {
          margin-top: -4px;
          &::before {
            bottom: 0;
            opacity: 1;
          }
        }
      }
    }
  }

  @media (max-width: 767.98px) {
    nav {
      .menu-mobile {
        visibility: visible;
        opacity: 1;
      }

      .menu {
        position: fixed;
        left: -100%;
        top: 0;
        background: ${({ theme }) => theme.colors['gray-50']};
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: center;
        padding-top: 6rem;
        z-index: 999;
        visibility: hidden;
        opacity: 0;
        transition: 0.5s ease all;

        &.active {
          visibility: visible;
          opacity: 1;
          left: 0;
        }
      }
    }
  }
`;
