import React from 'react';
import { Container, TableWrapper } from './styles';

interface CustomTableProps {
  data: any[];
  headers: string[];
}

export const CustomTable: React.FC<CustomTableProps> = ({ data, headers }) => {
  return (
    <TableWrapper>
      <Container>
        <thead>
          <tr>
            {headers.map((item, index) => (
              <th key={index}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody>{data.map((item, index) => item)}</tbody>
      </Container>
    </TableWrapper>
  );
};
