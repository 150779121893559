import React, { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import parse from 'html-react-parser';

import { NewsDTO } from '../../dtos/NewsDTO';
import { news } from '../../database/mock/news';
import { Container, Content } from './styles';

export const ViewPost: React.FC = () => {
  const [post, setPost] = useState<NewsDTO | undefined>();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const postId = searchParams.get('id');

    if (postId) {
      const resultPost = news.find(findPost => findPost.id === postId);

      setPost(resultPost);
    }
  }, [searchParams]);

  return (
    <Container data-aos="fade-up" data-aos-duration="800">
      <Content>
        <div className="content-cont">
          <div className="coll-left">
            {post && (
              <div className="item">
                <h2>{post.title}</h2>
                <span>{dayjs(post.createdAt).format('DD/MM/YYYY')}</span>

                <div className="image">
                  <img src={post.imageUrl} alt="Image_Post" />
                </div>

                <div className="description">{parse(post.description)}</div>
              </div>
            )}
          </div>

          <div className="coll-right">
            <div className="items">
              <h2>Recent Post</h2>

              {news.slice(0, 3).map(item => (
                <Link
                  className="item"
                  to={`/news/post/?id=${item.id}`}
                  key={item.id}
                >
                  <div>
                    <div className="image">
                      <img src={item.imageUrl} alt="Image_Post" />
                    </div>

                    <h2>{item.title}</h2>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </Content>
    </Container>
  );
};
