import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  justify-content: center;
  margin: 0 1rem;
  margin-bottom: 4rem;
`;

export const Content = styled.div`
  display: flex;
  max-width: 1016px;
  flex-direction: column;

  .content-cont {
    display: flex;
    flex-wrap: wrap;
    gap: 8rem;

    .coll-left {
      display: flex;
      flex-direction: column;
      flex: 1;
      flex-wrap: wrap;

      .item {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        .image {
          margin-top: 32px;
          width: 100%;
          border-radius: 0.5rem;
          overflow: hidden;
          min-width: 317.33px;
          height: 304px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        h2 {
          font-size: 2rem;
          font-weight: bold;
          line-height: 2.4rem;
          color: ${({ theme }) => theme.colors['gray-900']};
        }

        > span {
          margin-top: 24px;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5rem;
          color: ${({ theme }) => theme.colors['gray-700']};
        }

        .description {
          margin-top: 16px;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5rem;
          color: ${({ theme }) => theme.colors['gray-700']};
          text-align: justify;
        }
      }
    }

    .coll-right {
      display: flex;
      gap: 1rem;

      .items {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        > h2 {
          font-weight: 700;
          font-size: 1.5rem;
          line-height: 33.6px;
          color: ${({ theme }) => theme.colors['gray-800']};
        }

        .item {
          margin-top: 25px;
          width: 288px;

          > div {
            img {
              border-radius: 8px;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            > h2 {
              margin-top: 16px;
              font-weight: 700;
              font-size: 1rem;
              line-height: 24px;
              color: ${({ theme }) => theme.colors['gray-800']};
            }
          }
        }
      }
    }
  }
`;
