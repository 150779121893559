import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.colors['gray-50']};
`;

export const Content = styled.div`
  display: flex;
  max-width: 1016px;
  flex: 1;
  min-height: 581px;

  .description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    flex: 1;
    height: inherit;
    gap: 1rem;

    h1 {
      font-size: 3.375rem;
      color: ${({ theme }) => theme.colors['gray-900']};
      font-weight: 500;
    }

    p {
      color: ${({ theme }) => theme.colors['gray-700']};
      font-size: 1.5rem;
      font-weight: 400;
    }
  }

  .image {
    position: absolute;
    right: 0;
    max-width: 705px;
    max-height: 581px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  @media (max-width: 1199.98px) {
    .image {
      max-width: 580px;
    }
  }

  @media (max-width: 1016px) {
    .image {
      position: initial;
    }
  }

  @media (max-width: 767.98px) {
    flex-wrap: wrap;

    .image {
      max-width: 100%;
      width: 100%;
      justify-self: flex-end;
      margin-top: 2rem;

      img {
        object-fit: cover;
      }
    }
  }
`;
