import styled from 'styled-components';

export const Container = styled.section`
  margin: 48px 0;
  padding: 0 16px;
`;

export const Content = styled.div`
  max-width: 1016px;
  margin: 0 auto;

  > h2 {
    font-weight: 700;
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors['red-700']};
    text-transform: uppercase;
    margin-top: 64.43px;
  }

  .slick-slider {
    margin-top: 24px;

    .card {
      display: flex;
      justify-content: center;
      margin: 0 8px;

      img {
        object-fit: contain;
      }
    }
  }
`;
