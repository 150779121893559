import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  max-width: 1016px;
  flex-direction: column;
  flex: 1;
  margin: 3.5rem 1rem;
`;
