import React from 'react';
import ReactGA4 from 'react-ga4';
import { ItemPost } from '../../components/ItemPost';
import { PageHeader } from '../../components/PageHeader';
import { news } from '../../database/mock/news';

import { Container, Content } from './styles';

// Google Analytics
ReactGA4.send({ hitType: 'pageview', page: window.location.pathname });

export const PageNews: React.FC = () => {
  const newOrderByDate = news.sort(
    (a, b) => b.createdAt.getTime() - a.createdAt.getTime(),
  );

  return (
    <Container>
      <Content>
        <PageHeader
          // title="News"
          // subTitle="Stay tuned to Pathospotter in the media"
          title=""
          subTitle=""
        />

        <div className="items">
          {newOrderByDate.map(data => (
            <ItemPost data={data} key={data.id} />
          ))}
        </div>
      </Content>
    </Container>
  );
};
