import React from 'react';

import { Container, Content } from './styles';

export const Banner: React.FC = () => (
  <Container>
    <Content>
      <div className="description">
        <h1>
          Toward a new era <br />
          in Pathology
        </h1>

        <p>
          Digital pathology meets artificial <br />
          intelligence toward faster and more <br />
          accurate histological diagnosis.
          {/* The emergence of digital pathology <br />
          and the application of <br />
          artifical intelligence to the analysis of <br />
          histological sections has given rise to a <br />
          new area, named Computational Pathology. <br /> <br />
          Advances in Computer Vision should make <br />
          substantial changes in the routine <br />
          of pathologist&apos;s own work profile. */}
        </p>
      </div>

      <div className="image">
        <img src="/assets/images/banner.png" alt="Banner" />
      </div>
    </Content>
  </Container>
);
