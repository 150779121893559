import React from 'react';
import ReactGA4 from 'react-ga4';
import { CompTeam } from '../../components/CompTeam';
import { PageHeader } from '../../components/PageHeader';
import { teams } from '../../database/mock/teams';

import { Container, Content } from './styles';

// Google Analytics
ReactGA4.send({ hitType: 'pageview', page: window.location.pathname });

export const Team: React.FC = () => {
  return (
    <Container data-aos="fade-up" data-aos-duration="800">
      <Content>
        <PageHeader
          title="People who contribute to the PathoSpotter project"
          subTitle=""
        />

        <div className="current-span">
          <span>Current contributors</span>
        </div>

        <div className="items" data-aos="zoom-in" data-aos-duration="800">
          {teams.map(team => (
            <CompTeam key={team.id} data={team} />
          ))}
        </div>
        {/*
        <div className="footer">
          <div className="former">
            <span>Former contributors</span>
          </div>

          <div
            className="items-footer"
            data-aos="zoom-in"
            data-aos-duration="800"
          >
            <div className="group-items">
              <div className="item-footer">
                <h2>Full name</h2>

                <p>Institution of bond</p>
              </div>

              <div className="item-footer">
                <h2>Full name</h2>

                <p>Institution of bond</p>
              </div>

              <div className="item-footer">
                <h2>Full name</h2>

                <p>Institution of bond</p>
              </div>

              <div className="item-footer">
                <h2>Full name</h2>

                <p>Institution of bond</p>
              </div>
            </div>

            <div className="group-items-two">
              <div className="item-footer">
                <h2>Full name</h2>

                <p>Institution of bond</p>
              </div>

              <div className="item-footer">
                <h2>Full name</h2>

                <p>Institution of bond</p>
              </div>
            </div>
          </div>
        </div>
      */}
      </Content>
    </Container>
  );
};
