import React from 'react';

import { news } from '../../database/mock/news';
import { ItemPost } from '../ItemPost';
import { PageHeader } from '../PageHeader';
import { SeeAll } from '../SeeAll';

import { Container, Content } from './styles';

export const News: React.FC = () => (
  // data-aos-duration="800"
  // data-aos="fade-up"
  <Container>
    <Content>
      <PageHeader title="News" subTitle="News about the project." />
      <div className="items">
        {news.slice(0, 3).map(data => (
          <ItemPost key={data.id} data={data} />
        ))}
      </div>

      <div className="button">
        <SeeAll path="/news" />
      </div>
    </Content>
  </Container>
);
