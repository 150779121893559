import React from 'react';

import { Container } from './styles';

interface PageHeaderProps {
  title: string;
  subTitle: string;
}

export const PageHeader: React.FC<PageHeaderProps> = ({ title, subTitle }) => (
  <Container>
    <span>{title}</span>

    <h4>{subTitle}</h4>
  </Container>
);
