import React from 'react';

import { Container } from './styles';

interface SeeAllProps {
  path: string;
}

export const SeeAll: React.FC<SeeAllProps> = ({ path }) => {
  return <Container to={path}>See all</Container>;
};
