import React from 'react';
import { PageHeader } from '../PageHeader';

import { Container, Content } from './styles';

export const About: React.FC = () => (
  <Container data-aos="fade-up" data-aos-duration="800">
    <Content>
      <PageHeader
        title="About"
        subTitle="A suite of intelligent tools to support pathologists' daily practice"
      />

      <div className="description">
        <div className="col-left">
          <img src="/assets/images/about.png" alt="" />
        </div>
        <div className="col-right">
          <p>
            PathoSpotter aims to assist pathologists in computing the statistics
            of activity and chronicity of kidney lesions.
          </p>
          <p>
            The goal is to foster large-scale correlations of lesions 
            with clinical and historical data of
            patients while creating a set of tools to aid
            the pathologists' daily practices.
          </p>
          <p>
            The project encompasses specialists from different scientific areas
            and worldwide research centers who colaborate to the evolution
            of Computational Pathology considering:
          </p>
        </div>
      </div>

      <div className="items">
        <div className="item">
          <span>01</span>
          <p>
            To leverage the research in Computational Pathology.
          </p>
        </div>

        <div className="item">
          <span>02</span>
          <p>
            To help pathologists tackle the tasks required for accurate and faster diagnoses.
          </p>
        </div>

        <div className="item">
          <span>03</span>
          <p>
            To facilitate learning nephropathology.
          </p>
        </div>
      </div>
    </Content>
  </Container>
);
