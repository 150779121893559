import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from '../../components/Header';

import { Container } from './styles';

export const Layout: React.FC = () => (
  <Container>
    <Header />

    <Outlet />

    <div className="COPYRIGHT">
      <h2>Copyright © The Pathospotter Project | All rights reserved.</h2>
    </div>
  </Container>
);
