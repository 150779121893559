import styled, { css } from 'styled-components';

interface ContainerProps {
  isSeeAll: boolean;
}

export const Container = styled.section<ContainerProps>`
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.colors['gray-50']};

  ${({ isSeeAll, theme }) =>
    isSeeAll &&
    css`
      background: ${theme.colors.white};
    `}
`;

export const Content = styled.div`
  display: flex;
  max-width: 1016px;
  flex: 1;
  flex-direction: column;
  margin: 3.5rem 1rem;

  .items {
    display: flex;
    flex-wrap: wrap;
    gap: 6rem 2rem;
    margin-top: 80px;
    align-items: stretch;

    .item {
      display: flex;
      flex-direction: column;
      flex: calc(50% - 2rem);
      padding: 1rem;
      border-radius: 0.5rem;
      transition: 0.3s ease all;
      cursor: pointer;
      img {
        width: 64px;
        height: 64px;
      }

      div {
        display: flex;
        flex-direction: column;

        > span {
          font-weight: 600;
          font-size: 1.25rem;
          line-height: 30px;
          margin-top: 32px;
          color: ${({ theme }) => theme.colors['gray-900']};
        }

        span.private {
          display: flex;
          width: 94px;
          font-weight: 600;
          font-size: 1rem;
          padding: 0.2rem 0.8rem;
          border-radius: 500px;
          background: ${({ theme }) => theme.colors['red-700']};
          color: ${({ theme }) => theme.colors.white};
          text-transform: uppercase;
        }

        p {
          font-size: 1.25rem;
          line-height: 30px;
          font-weight: 400;
          margin-top: 8px;
          color: ${({ theme }) => theme.colors['gray-700']};
        }
      }
      &:hover {
        background: ${({ theme }) => theme.colors['red-100']};
        transform: scale(1.005);
      }
    }
  }

  .image-container {
    display: flex; 
  gap: 10px; 
  }


  .button {
    display: flex;
    justify-content: center;
    margin-top: 110px;
  }
`;
