import { UserTeamDTO } from '../../dtos/UserTeamDTO';

// Cada pessoa é um item do Array UserTeamDTO
// O campo id é uma chave que serve apenas para diferenciação e pode ter qualquer valor.
// O campo avatar pode ser o link para uma imagem ou um arquivo do próprio site.
//  {
//    id: '6b90a77c-08cb-4ede-a586-2a70dd0aa39c',
//    avatar: 'https://avatars.githubusercontent.com/u/68488657?v=4',
//    avatar: '/assets/images/washington.png',
//    fullName: 'Washington L.C. dos Santos',
//    office: 'Coordinator',
//    contacts: {
//      email: 'email@gmail.com',
//      document: 'file or link',
//      site: 'http://....'
//    },
//  },

export const teams: UserTeamDTO[] = [
  {
    id: 'p1',
    avatar: '/assets/images/washington.png',
    fullName: 'Washington L.C. dos Santos',
    office: 'Coordinator at FIOCRUZ',
    contacts: {
      email: 'wluis@bahia.fiocruz.br',
    },
  },

  {
    id: 'p2',
    avatar: '/assets/images/angelo-amancio.png',
    fullName: 'Angelo Amâncio Duarte',
    office: 'Coordinator at UEFS',
    contacts: {
      email: 'angeloduarte@uefs.br',
      site: 'http://lacad.uefs.br',
    },
  },

  {
    id: 'p3',
    avatar: '/assets/images/luciano-reboucas.png',
    fullName: 'Luciano Rebouças de Oliveira',
    office: 'Coordinator at UFBA',
    contacts: {
      email: 'lrebouca@ufba.br',
      site: 'https://www.ivisionlab.ufba.br',
    },
  },

  {
    id: 'p20',
    avatar: '/assets/images/pinakiSarder.png',
    fullName: 'Pinaki Sarder',
    office: 'Colaborator',
    contacts: {
      email: 'pinaki.sarder@ufl.edu',
      site: 'https://linkedin.com/in/pinaki-sarder-94a9b99',
    },
  },

  {
    id: 'p4',
    avatar: '/assets/images/michele.png',
    fullName: 'Michele Fúlvia Angelo',
    office: 'Researcher',
    contacts: {
      email: 'mfangelo@uefs.br',
    },
  },

  {
    id: 'p5',
    avatar: '/assets/images/flavio-barros.png',
    fullName: 'Flávio de Barros Vidal',
    office: 'Researcher',
    contacts: {
      email: 'fbvidal@unb.br',
    },
  },

  {
    id: 'p6',
    avatar: '/assets/images/emanuele-marques.png',
    fullName: 'Emanuele Santos',
    office: 'Researcher',
    contacts: {
      email: 'emanuele@dc.ufc.br',
    },
  },

  {
    id: 'p7',
    avatar: '/assets/images/rodrigo-melo.png',
    fullName: 'Rodrigo de Melo Souza Veras',
    office: 'Researcher',
    contacts: {
      email: 'rveras@ufpi.edu.br',
      site: '',
      document: '',
    },
  },

  {
    id: 'p8',
    avatar: '/assets/images/rodrigo-tripodi.png',
    fullName: 'Rodrigo Tripodi Calumby',
    office: 'Researcher',
    contacts: {
      email: 'rtcalumby@uefs.br',
      site: '',
      document: '',
    },
  },

  {
    id: 'p9',
    avatar: '/assets/images/angelo-conrado.png',
    fullName: 'Angelo Conrado Loula',
    office: 'Researcher',
    contacts: {
      email: 'angelocl@uefs.br',
      site: '',
      document: '',
    },
  },

  {
    id: 'p10',
    avatar: '/assets/images/luiz-otavio.png',
    fullName: 'Luiz Otávio de Oliveira',
    office: 'PhD Student',
    contacts: {
      site: '',
      document: '',
    },
  },

  {
    id: 'p11',
    avatar: '/assets/images/paulo-chagas.png',
    fullName: 'Paulo Chagas Jr.',
    office: 'PhD Student',
    contacts: {
      site: '',
      document: '',
    },
  },

  {
    id: 'p12',
    avatar: '/assets/images/george-oliveira.png',
    fullName: 'George Oliveira Barros',
    office: 'PhD Student',
    contacts: {
      site: '',
      document: '',
    },
  },

  {
    id: 'p13',
    avatar: '/assets/images/justino-duarte.png',
    fullName: 'Justino Duarte Santos',
    office: 'PhD Student',
    contacts: {
      site: '',
      document: '',
    },
  },

  {
    id: 'p20',
    avatar: '/assets/images/marcos-sousa.png',
    fullName: 'Marcos Morais de Sousa',
    office: 'MsC Student',
    contacts: {
      site: '',
      document: '',
    },
  },

  {
    id: 'p14',
    avatar: '/assets/images/joacy-mesquita.png',
    fullName: 'Joacy Mesquita da Silva',
    office: 'MsC Student',
    contacts: {
      site: '',
      document: '',
    },
  },

  {
    id: 'p15',
    avatar: '/assets/images/gledson-oliveira.png',
    fullName: 'Gledson Oliveira',
    office: 'MsC Student',
    contacts: {
      site: '',
      document: '',
    },
  },

  // {
  //   id: 'p16',
  //   avatar: '/assets/images/ellen-chalegre.png',
  //   fullName: 'Ellen Chalegre Aguiar',
  //   office: 'Graduate Student',
  //   contacts: {
  //     site: '',
  //     document: '',
  //   },
  // },

  {
    id: 'p17',
    avatar: '/assets/images/abel-ramalho.png',
    fullName: 'Abel Ramalho Galvão',
    office: 'Graduate Student',
    contacts: {
      site: '',
      document: '',
    },
  },
];
