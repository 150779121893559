import styled from 'styled-components';

export const Container = styled.div`
  .COPYRIGHT {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 80px;
    background: ${({ theme }) => theme.colors['gray-50']};

    h2 {
      font-weight: 600;
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors['gray-800']};
      text-transform: uppercase;
    }
  }
`;
