import React from 'react';

// import { PageHeader } from '../PageHeader';
import { Container, Content } from './styles';

export const Footer: React.FC = () => {
  const handleBottomToTop = (): void => {
    window.scrollTo(0, 0);
  };

  return (
    <Container>
      <Content>
        <div className="p-logo">
          <div>
            <img
              src="/assets/images/logo.png"
              alt="logo"
              className="footer-logo"
            />

            <div className="div-texto-logo">
              <p>
                The project PathoSpotter aimed to assist pathologists in the
                identification of
              </p>
              <p>glomerular lesions from kidney biopsies.</p>
            </div>
          </div>

          <div className="button-to-top">
            <button type="button" onClick={handleBottomToTop}>
              <img src="/assets/svg/arrow-up.svg" alt="button" />
            </button>
          </div>
        </div>

        <div className="menu-footer">
          <div className="col-left">
            <div className="item">
              <h2>Project</h2>
              <div className="menu-items">
                <a href="/tools">Tools</a>
                <a href="/news">News</a>
                <a href="/publications">Publications</a>
              </div>
            </div>

            <div className="item">
              <h2>About Us</h2>
              <div className="menu-items">
                <nav>
                  <a href="/team">Team</a>
                </nav>
              </div>
            </div>
          </div>

          <div className="col-right">
            <div className="item">
              <h2>Address</h2>

              <div className="menu-items">
                <a
                  href="https://g.page/fiocruzbahia?share"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instituto Gonçalo Moniz/FIOCRUZ Bahia <br />
                  Rua Waldemar Falcão, 121 - Candeal <br />
                  40296-710, Salvador-Bahia-Brazil
                </a>
              </div>
            </div>

            <div className="item">
              <h2>Contact</h2>

              <div className="menu-items">
                <a href="+557131762202">phone:+55-71-3176-2202</a>

                <a href="mailto:wluis@bahia.fiocruz.br">
                  wluis@bahia.fiocruz.br
                </a>
              </div>
            </div>
          </div>
        </div>
      </Content>
    </Container>
  );
};
